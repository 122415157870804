export const offerFeatures = [
  "mobile only",
  "students only",
  "new customers only",
  "military discount",
];

export const offerSources = [
  "affiliate_newsletter",
  "affiliate_network",
  "store_website_directly",
  "store_email_newsletter",
  "competitor_website",
  "social_media",
  "users_added",
  "other",
];

export const offerTypes = [
  "sitewide amount",
  "sitewide percent",
  "free shipping",
  "generic free shipping",
  "product deal",
  "sales",
  "clearance",
  "BOGO",
  "Newsletter Signup",
  "Free Product",
  "Refer a Friend",
  "Other",
];
