function getShortCouponInfo(coupon: Record<string, any>) {
  const {
    offer_type,
    savings_amount,
    savings_percent,
    offer_starting_at,
    up_to_savings_percent,
    store,
  } = coupon;

  if (offer_type === "sitewide amount") {
    return (
      (store.location?.toLowerCase() === "uk" ? "£" : "$") +
      (Number(savings_amount) || 0) +
      " Off"
    );
  }

  if (offer_type === "sitewide percent") {
    return Number(savings_percent) + "% Off";
  }

  if (
    offer_type === "product deal" ||
    offer_type === "clearance" ||
    offer_type === "Newsletter Signup"
  ) {
    if (Number(up_to_savings_percent) > 0) {
      return "Up to " + Number(up_to_savings_percent) + "%  Off";
    }

    if (Number(savings_amount) > 0) {
      return (
        (store.location?.toLowerCase() === "uk" ? "£" : "$") +
        Number(savings_amount) +
        " Off"
      );
    }

    if (Number(savings_percent) > 0 || Number(up_to_savings_percent) > 0) {
      return (
        (Number(savings_percent) || Number(up_to_savings_percent)) + "% Off"
      );
    }
  }

  if (offer_type === "sales") {
    const hasPercentValue =
      Number(up_to_savings_percent) > 0 || Number(savings_percent) > 0;

    if (!hasPercentValue && Number(offer_starting_at) > 0) {
      return store.location?.toLowerCase() === "uk"
        ? "£"
        : "$" + Number(offer_starting_at) + "Off";
    }

    if (
      Number(up_to_savings_percent) > 0 ||
      Number(savings_amount) > 0 ||
      Number(savings_percent) > 0
    ) {
      return (
        (Number(savings_amount) ||
          Number(savings_percent) ||
          Number(up_to_savings_percent)) + "% Off"
      );
    }
  }

  return null;
}

export default getShortCouponInfo;
