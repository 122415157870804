import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import Contacts from "layout/sales_crm/Contacts";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getCRMNetworks from "redux/thunks/sales_crm/getCRMNetworks";
import getContacts from "redux/thunks/sales_crm/getContacts";
import getEntityTypes from "redux/thunks/sales_crm/getEntityTypes";

const SalesCRMContacts = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const contactsData = useAppSelector((state) => state.getContacts);

  const { page } = Object.fromEntries(params);

  useEffect(() => {
    dispatch(getContacts({ page: 1 }));
    dispatch(getEntityTypes());
    dispatch(getCRMNetworks());
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(
        getContacts({
          page,
        })
      );
    }
  }, [page]);

  useEffect(() => {
    if (typeof searchText === "string") {
      dispatch(
        getContacts({
          search: searchText,
          page: 1,
        })
      );
    }
  }, [searchText]);

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const debouncedOnChange = debounce((e) => {
    setSearchText(String(e.target.value));
  }, 1000);

  return (
    <Contacts
      page={Number(page) || 1}
      searchText={searchText}
      onPageChange={onPageChange}
      contactsData={contactsData}
      debouncedOnChange={debouncedOnChange}
    />
  );
};

export default SalesCRMContacts;
