import DataTable from "components/common/layout/DataTable";
import { ActionState, PaginatedActionState } from "types/data";
import { Link } from "types/model/Link";
import NEWSLETTER_COLUMNS from "constants/tables/headers/oma/storeNewsletterFeed";
import NewsLetterFeedRow from "components/links/NewsLetterFeedRow";

type Props = {
  getAllLinksState: PaginatedActionState<Link>;
  onActivatePressed: (arg: Record<string, any>) => void;
  completeNewsletter: (item: Record<string, any>) => void;
  completeNewsLetterData: ActionState;
  createCouponData: ActionState;
};

const OMADirectStoreNewslettersComponent = ({
  onActivatePressed,
  getAllLinksState: { loading, data },
  completeNewsLetterData,
  completeNewsletter,
  createCouponData,
}: Props) => {
  return (
    <div>
      <DataTable
        totalCount={data.meta.count}
        columns={NEWSLETTER_COLUMNS}
        rows={data.items}
        rowRenderer={(row: any) => (
          <NewsLetterFeedRow
            item={row}
            createCouponData={createCouponData}
            onReviewPressed={onActivatePressed}
            completeNewsletter={completeNewsletter}
            completeNewsLetterData={completeNewsLetterData}
          />
        )}
        loading={loading}
      />
    </div>
  );
};

export default OMADirectStoreNewslettersComponent;
