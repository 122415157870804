import { useAppSelector } from "hooks/useRedux";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  disabled: boolean;
};

const MarketingDropDown = ({ disabled }: Props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const {
    data: { items: pendingTodos },
  } = useAppSelector((state) => state.getTodoStats);

  const userApps = useAppSelector((state) => state.getApps);

  const appId =
    userApps.data.items.find((it) => it.name === "Marketing")?.id || "";

  // const pendingAppActions = pendingTodos.filter(
  //   (el) => el.app === Number(appId)
  // );

  const pendingAppActions = [];
  return (
    <div className="relative">
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        <button
          disabled={disabled}
          onClick={() => {
            setOpen((value) => !value);
          }}
          className={twMerge(
            "font-semibold rounded-full bg-gray-100 text-sm px-4 py-2 lg:px-5 lg:py-2.5 mx-2",
            location.pathname.includes("/marketing") &&
              "text-white bg-primary-700"
          )}
        >
          <div className="relative inline-block">
            Marketing
            {pendingAppActions.length > 0 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/todo?app_id=${appId}`);
                }}
                className="absolute top-1 right-0 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full transform translate-x-1/2 -translate-y-1/2"
              >
                {pendingAppActions.length}
              </button>
            )}
          </div>
        </button>
        {open && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <Link
              to={"/marketing/email"}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Email Marketing
            </Link>

            <Link
              to={"/marketing/search"}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Search Marketing
            </Link>

            <Link
              to={"/marketing/browser-extension"}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Browser Extension
            </Link>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default MarketingDropDown;
