import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  useEffect,
  ChangeEventHandler,
  useState,
  FormEventHandler,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OMAAffiliateNewslettersComponent from "layout/oma/OMAAffiliateNewslettersComponent";
import { Link } from "types/model/Link";
import toast from "react-hot-toast";
import { Option } from "types/option";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import getAffiliateNewLetterFeed from "redux/thunks/links/newsletters/getAffiliateNewLetterFeed";
import completeNewsletter from "redux/thunks/links/newsletters/completeAffiliateNewsletter";
import getAffiliateEmailAccounts from "redux/thunks/links/newsletters/getAffiliateEmailAccounts";
import generateCouponInfo from "redux/thunks/links/newsletters/generateCouponInfo";

const OMAAffiliateNewsletters = () => {
  const [searchStr, setSearchStr] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>([]);
  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);

  const {
    page,
    network_id,
    has_un_matched_emails,
    email_account = "3",
  } = Object.fromEntries([...searchParams]);

  const {
    getAffiliateNewsletterFeed: getNewsletterFeedState,
    completeAffiliateNewsLetter: completeNewsLetterData,
    affiliateNetworks,
    createUpdateCoupon: createCouponData,
    getAffiliateEmailAccounts: getAffiliateEmailAccountsData,
    generateCouponInfo: generateCouponInfoData,
  } = useAppSelector(
    ({
      getAffiliateNewsletterFeed,
      completeAffiliateNewsLetter,
      affiliateNetworks,
      createUpdateCoupon,
      getAffiliateEmailAccounts,
      generateCouponInfo,
    }) => ({
      getAffiliateNewsletterFeed,
      completeAffiliateNewsLetter,
      affiliateNetworks,
      createUpdateCoupon,
      getAffiliateEmailAccounts,
      generateCouponInfo,
    })
  );

  const emailAccountOptions = getAffiliateEmailAccountsData.data.items?.map(
    (el: Record<string, any>) => ({
      label: el.email_address,
      value: el.id,
    })
  ) as Array<Option>;

  const onActivatePressed = (link: Record<string, any>) => {
    navigate(
      `/oma/program?&mid=${link?.network_advertiser?.external_id}&program_id=${link?.network_advertiser?.id}&network=${link?.network_advertiser?.network}&link=${link.id}&tab=create-coupon`,

      {
        state: link,
      }
    );
  };

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const onFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.name === "search_text") {
      setSearchStr(e.target.value);
      return;
    }
    if (e.target.checked) {
      const url = new URL(window.location.href);
      url.searchParams.set("page", "1");
      url.searchParams.set(e.target.name, "1");
      navigate(url.search);
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set("page", "1");
      url.searchParams.set(e.target.name, "0");
      navigate(url.search);
    }
  };

  const onSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    url.searchParams.set("page", "1");
    url.searchParams.set("search_text", searchStr);
    navigate(url.search);
  };

  const onItemSelectChange = (link: Link) => {
    const isChecked = selectedItems.map((el) => el.id).includes(link.id);

    if (isChecked) {
      setSelectedItems((all) => all.filter((it) => it.id !== link.id));
    } else {
      setSelectedItems((all) => [link, ...all]);
    }
  };

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items.map((item) => ({
        label: item.name,
        value: item.network_id,
        selected: network_id === item.network_id,
      }));
      setNetworkOptions(allAffiliateNetworks);
    } else {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.items.length]);

  useEffect(() => {
    dispatch(
      getAffiliateNewLetterFeed({
        page: Number(page) || 1,
        network_id,
        email_account: email_account,
        has_un_matched_emails,
      })
    );
  }, [searchParams]);

  const handleGenerateCouponInfo = (link: Record<string, any>) => {
    dispatch(generateCouponInfo(link)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (Array.isArray(res.payload) && res.payload.length) {
          toast.success(`${res.payload.length} Offers Found`);
        }
      }
    });
  };

  const handleCompleteNewsLetter = (link?: Record<string, any>) => {
    const items = [link, ...selectedItems].filter(
      (el: Record<string, any> | undefined) => el?.id && Boolean(el)
    );
    if (items.length) {
      dispatch(
        completeNewsletter({
          items: items.map((item) => ({
            email_id: item?.email_id,
            id: item?.id,
          })),
        })
      )
        .then((res) => {
          setSelectedItems([]);
          if (res.meta.requestStatus === "fulfilled") {
            toast.success("Newsletter marked as complete");
          }
        })
        .catch((err) => {
          //
        });
    }
  };

  const onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const isSelected = e.currentTarget.checked;
    setNetworkOptions((opts) =>
      opts.map((v) =>
        v.value === e.currentTarget?.name ? { ...v, selected: isSelected } : v
      )
    );

    const url = new URL(window.location.href);
    url.searchParams.set("network_id", e.target.value);

    navigate(url.search);
  };

  const onSelectedRankChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNetworkOptions((opts) =>
      opts.map((v) => (v.value === e.currentTarget?.name ? { ...v } : v))
    );

    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target.value);

    navigate(url.search);
  };

  useEffect(() => {
    dispatch(getAffiliateEmailAccounts({ page: 1 }));
  }, []);

  return (
    <OMAAffiliateNewslettersComponent
      onPageChange={getNewPage}
      createCouponData={createCouponData}
      network={network_id}
      currentPage={Number(page) || 1}
      onActivatePressed={onActivatePressed}
      getAllLinksState={getNewsletterFeedState}
      onFilterChange={onFilterChange}
      onSearchSubmit={onSearchSubmit}
      onItemSelectChange={onItemSelectChange}
      selectedItems={selectedItems}
      completeNewsletter={handleCompleteNewsLetter}
      generateCouponInfo={handleGenerateCouponInfo}
      completeNewsLetterData={completeNewsLetterData}
      networkOptions={networkOptions}
      onSelectedNetworkChange={onSelectedNetworkChange}
      onSelectedRankChange={onSelectedRankChange}
      emailAccountOptions={emailAccountOptions}
      email_account={email_account}
      generateCouponInfoData={generateCouponInfoData}
    />
  );
};

export default OMAAffiliateNewsletters;
