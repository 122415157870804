import { Dispatch, Fragment, SetStateAction, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import Spinner from "components/common/layout/Spinner";

type Props = {
  onConfirm?: () => void;
  onOpen?: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  doneText?: string;
  doneBtnClass?: string;
  cancelBtnClass?: string;
  showCancel?: boolean;
  showDone?: boolean;
  loading?: boolean;
  wrapperClassName?: string;
  variant?: "sm" | "md" | "default" | "lg";
  doneLoading?: boolean;
  doneOnClick?: () => void;
  cancelElement?: JSX.Element | null;
  title?: string;
};

export default function Modal({
  onConfirm,
  open,
  onOpen,
  setOpen,
  doneText,
  doneBtnClass,
  cancelBtnClass,
  showCancel = true,
  showDone = true,
  loading,
  wrapperClassName,
  variant,
  doneLoading,
  doneOnClick,
  cancelElement,
  title,
  children,
}: Props) {
  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className=" relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6 flex flex-col justify-between",
                  variant == "sm" && "w-80 min-h-[200px]",
                  variant == "lg" && "w-1/2 min-h-[550px]",
                  variant == "md" && "w-[420px] min-h-[320px]",
                  variant == "default" && "w-[45%] min-h-[550px]",
                  wrapperClassName
                )}
              >
                <div>
                  {title && <h2 className="text-lg mb-4">{title}</h2>}
                  <div>{children}</div>
                </div>
                <div className="mt-5 px-4 flex items-end w-full  justify-end">
                  {cancelElement}
                  {showCancel && (
                    <button
                      disabled={loading}
                      type="button"
                      className={twMerge(
                        "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:mt-0 sm:w-auto",
                        cancelBtnClass
                      )}
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  )}

                  {showDone && (
                    <button
                      disabled={loading}
                      type="button"
                      className={twMerge(
                        "inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto sm:ml-3",
                        doneBtnClass
                      )}
                      onClick={() => {
                        if (typeof doneOnClick === "function") {
                          doneOnClick();
                        } else {
                          onConfirm?.();
                        }
                      }}
                    >
                      {loading || doneLoading ? (
                        <Spinner className="h-5 w-5" />
                      ) : (
                        doneText || "OK"
                      )}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
