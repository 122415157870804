import DynamicFAQInput from "components/common/DynamicFAQInput";
import { ChangeEventHandler } from "react";

type Props = {
  faqs: Record<string, any>[];
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const Faqs = ({ faqs, onChange }: Props) => {
  return (
    <section className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-8">
      <DynamicFAQInput key={faqs.length} faqs={faqs} onFormChange={onChange} />
    </section>
  );
};

export default Faqs;
