import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import axios from "helpers/axios";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

const handleUpdateProposal = async (payload: Record<string, any>) => {
  if (payload.manualIOFile) {
    const fileName = payload.manualIOFile.name
      .substr(0, payload.manualIOFile.name.lastIndexOf("."))
      .replaceAll(/ /g, "-");
    const s3 = new S3({
      ...defaultS3Config,
      dirName: `img/${localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      )}/ios/uploaded/${payload.id}`,
    });
    const result = await s3.uploadFile(payload.manualIOFile, fileName);
    const { location } = result;
    payload["manual_signed_io_file"] = location;
  }
  return axios.put(`/admin/sales/proposals/${payload.id}`, payload);
};

export default createAsyncThunk(
  "sales/create-update-delete-proposal",
  async (payload: Record<string, any>, ThunkAPI) => {
    try {
      const response = payload.id
        ? payload.delete
          ? await axios.delete(`/admin/sales/proposals/${payload.id}`)
          : await handleUpdateProposal(payload)
        : await axios.post(`/admin/sales/proposals`, payload);
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
