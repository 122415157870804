import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import getActivePrograms from "redux/thunks/app/api-clients/getActivePrograms";
import { Option } from "types/option";
import ActiveProgramsComponent from "layout/bma/ActivePrograms";
import { debounce } from "lodash";
import exportCSVResource from "redux/thunks/app/api-clients/exportCSVResource";
import { resetExportResource } from "redux/slices/clients/export-resource";

const ActivePrograms = () => {
  const route = useLocation();
  const isOMA = route.pathname.includes("oma");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>([]);
  const tab = searchParams.get("tab");

  const {
    page,
    primary_network = "",
    network_status,
    search = "",
    location = "",
    has_open_ppc_policy = "0",
    has_no_faqs = "0",
    has_no_coupons = route.pathname === "/" ? "" : "1",
    has_no_codes = "0",
    coupon_count = "0",
    is_free = "0",
    is_ad_center = "0",
    rank,
  } = Object.fromEntries([...searchParams]);

  const {
    getActivePrograms: getActiveProgramsData,
    affiliateNetworks,
    exportResource,
  } = useAppSelector(
    ({ getActivePrograms, affiliateNetworks, exportResource }) => ({
      getActivePrograms,
      affiliateNetworks,
      exportResource,
    })
  );

  const debounceFn = useCallback(
    debounce((e) => {
      const url = new URL(window.location.href);
      url.searchParams.set("search", String(e.target.value));
      navigate(url.search);
    }, 1000),
    []
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    debounceFn(e);
  };
  const onSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);

    navigate(url.search);
  };

  const getData = useCallback(
    (page = 1) => {
      dispatch(getActivePrograms({ page }));
    },
    [dispatch]
  );

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items.map((item) => ({
        label: item.name,
        value: item.network_id,
        selected: primary_network === item.network_id,
      }));
      setNetworkOptions(allAffiliateNetworks);
    }
  }, [affiliateNetworks.data.items.length]);

  const searchQueryValues = {
    page: Number(page) || 1,
    primary_network,
    search,
    location,
    has_open_ppc_policy,
    has_no_faqs,
    is_free,
    is_ad_center,
    sort_by: isOMA ? "-updated" : "-created",
    has_no_codes: has_no_codes,
    has_no_coupons: has_no_coupons,
    rank: rank || "",
    coupon_count: isOMA && (coupon_count || "0"),
  };

  useEffect(() => {
    dispatch(getActivePrograms(searchQueryValues));
  }, [getData, searchParams]);

  useEffect(() => {
    if (allSelected) {
      setSelectedItems(getActiveProgramsData.data.results);
    } else {
      setSelectedItems([]);
    }
  }, [allSelected]);

  const onFiltersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target?.value);
    navigate(url.search);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target?.value);
    navigate(url.search);
  };

  const onItemSelectChange = (coupon: Record<string, any>) => {
    const isChecked = selectedItems.map((el) => el.id).includes(coupon.id);

    if (isChecked) {
      setSelectedItems((all) => all.filter((it) => it.id !== coupon.id));
    } else {
      setSelectedItems((all) => [coupon, ...all]);
    }
  };

  const handleSelectAll = () => {
    const allChecked =
      selectedItems.length === getActiveProgramsData.data.count || allSelected;

    if (allChecked) {
      setSelectedItems([]);
    } else {
      setSelectedItems((curr) => [
        ...curr,
        ...getActiveProgramsData.data.results,
      ]);
    }
  };

  const onShowAll = () => {
    setSelectedItems([]);
    dispatch(
      getActivePrograms({
        page: 1,
        size: getActiveProgramsData.data.count,
      })
    );
  };

  const goToAdd = () => {
    navigate(`/oma`);
  };

  const onViewPage = (store: Record<string, any>) => {
    if (isOMA) {
      navigate(
        `/oma/program?slug=${store.slug}&tab=create-coupon&network=${store.primary_network.name}&mid=${store.primary_network.network_mid}&client_id=${store.client}`
      );
    } else {
      navigate(`/bma/programs?slug=${store.slug}&client_id=${store.client}`);
    }
  };

  const onViewRecentlyDeactivated = () => {
    navigate(`/bma/deactivated-programs`);
  };

  const onViewCommissions = () => {
    navigate(`/bma/commission-rate-changes`);
  };

  const onExportAll = (fields = []) => {
    dispatch(
      exportCSVResource({
        resource: "stores",
        network: primary_network,
        location: location,
        fields,
      })
    );
  };

  useEffect(() => {
    if (!!exportResource.data) {
      dispatch(resetExportResource());
      setAllSelected(false);
    }
  }, [exportResource]);

  return (
    <ActiveProgramsComponent
      goToAdd={goToAdd}
      onChange={onChange}
      has_open_ppc_policy={has_open_ppc_policy}
      has_no_faqs={has_no_faqs}
      is_free={is_free}
      is_ad_center={is_ad_center}
      activeTab={tab || "0"}
      onPageChange={getNewPage}
      getActiveProgramsData={getActiveProgramsData}
      networkOptions={networkOptions}
      onFiltersChange={onFiltersChange}
      network_status={network_status}
      currentPage={Number(page) || 1}
      onViewPage={onViewPage}
      onViewRecentlyDeactivated={onViewRecentlyDeactivated}
      onViewCommissions={onViewCommissions}
      onSearchChange={onSearchChange}
      onSearchSubmit={onSearchSubmit}
      onShowAll={onShowAll}
      onItemSelectChange={onItemSelectChange}
      handleSelectAll={handleSelectAll}
      selectedItems={selectedItems}
      allSelected={allSelected}
      setAllSelected={setAllSelected}
      exportResourceData={exportResource}
      onExportAll={onExportAll}
      isOMA={isOMA}
      searchQueryValues={searchQueryValues}
    />
  );
};

export default ActivePrograms;
