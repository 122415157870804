const ACTIVE_PROGRAM_COLUMNS = [
  {
    key: "",
    isSortable: false,
    label: "",
    component: "components/common/Checkbox",
  },

  {
    key: "",
    isSortable: false,
    label: "Program Name",
  },
  {
    key: "",
    isSortable: false,
    label: "Homepage Website URL",
  },
  {
    key: "",
    isSortable: false,
    label: "Network",
  },
  {
    key: "",
    isSortable: false,
    label: "Merchant ID (MID)",
  },

  {
    key: "",
    isSortable: false,
    label: "Page Activated",
  },


  {
    key: "",
    isSortable: false,
    label: "Action",
  },
];


export default ACTIVE_PROGRAM_COLUMNS;
