import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Advertiser } from "types/model/Advertiser";
import { Option } from "types/option";
import { toast } from "react-hot-toast";
import BMAHomeComponent from "layout/bma/BMAHome";
import IgnoreAdvertiser from "redux/thunks/advertisers/IgnoreAdvertiser";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";
import getAdvertisers from "redux/thunks/advertisers/getAdvertisers";

const BMAHome = () => {
  const dispatch = useAppDispatch();
  const [searchTxtValue, setSearchText] = useState("");
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const {
    page = 1,
    network_id = "",
    network_status = "JOINED",
    search_text,
    search_intent,
    has_open_ppc_policy,
    has_no_faqs,
    is_free,
    is_ad_center = "0",
  } = Object.fromEntries([...searchParams]);

  const {
    getAdvertisers: { data, loading, error },
    affiliateNetworks,
    ignoreAdvertisers: { loading: ignoreLoading },
  } = useAppSelector(
    ({ getAdvertisers, affiliateNetworks, ignoreAdvertisers }) => ({
      getAdvertisers,
      affiliateNetworks,
      ignoreAdvertisers,
    })
  );

  const queryParamValues = {
    page: Number(page) || 1,
    network_id,
    network_status,
    search_text,
    search_intent,
    has_open_ppc_policy,
    has_no_faqs,
  };

  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);
  const [selectedItems, setSelectedItems] = useState<Advertiser[]>([]);

  const getData = useCallback(
    (page = 1) => {
      dispatch(getAdvertisers({ page }));
    },
    [dispatch]
  );

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items
        .map((item) => ({
          label: item.name,
          value: item.network_id,
          selected: network_id === item.network_id,
        }))
        .sort((a, b) => {
          if (a.selected === b.selected) {
            return -1;
          } else {
            return 1;
          }
        });
      setNetworkOptions(
        allAffiliateNetworks.some((it) => it.selected)
          ? allAffiliateNetworks
          : [{ label: "", value: "", selected: false }, ...allAffiliateNetworks]
      );
    } else {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.items.length]);

  useEffect(() => {
    dispatch(getAdvertisers(queryParamValues));
  }, [getData, searchParams]);

  const onSelectedNetworkChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setNetworkOptions((opts) =>
      opts.map((v) =>
        v.value === e.currentTarget?.value ? { ...v, selected: true } : v
      )
    );

    const url = new URL(window.location.href);
    url.searchParams.set("network_id", e.currentTarget?.value);

    if (!e.currentTarget?.value.length) {
      url.searchParams.delete("network_id");
    }
    url.searchParams.set("page", "1");

    navigate(url.search);
  };

  const onStatusChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", "1");
    url.searchParams.set(e.target.name, e.target?.value);
    navigate(url.search);
  };

  const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const onItemSelectChange = (advertiser: Advertiser) => {
    const isChecked = selectedItems.map((el) => el.id).includes(advertiser.id);

    if (isChecked) {
      setSelectedItems((all) => all.filter((it) => it.id !== advertiser.id));
    } else {
      setSelectedItems((all) => [advertiser, ...all]);
    }
  };

  const onSearchSubmit = (e: any) => {
    e.preventDefault();
    const url = new URL(window.location.origin);
    url.searchParams.set("page", "1");
    url.searchParams.set("search_text", searchTxtValue);
    url.searchParams.set("search_intent", "pending-programs");
    navigate(url.pathname + url.search);
  };

  useEffect(() => {
    if (search_text) {
      setSearchText(search_text);
    }
  }, []);

  const goToAdd = () => {
    navigate(`/oma`);
  };

  const onActivatePressed = (advertiser: Advertiser) => {
    navigate(`/bma/activate?mid=${advertiser.id}`, {
      state: advertiser,
    });
  };

  const handleIgnoreAdvertisers = (advertiser?: Advertiser) => {
    const ids: string[] = [
      ...selectedItems.map((it) => String(it.id)),
      ...(advertiser?.id ? [String(advertiser?.id)] : []),
    ];

    dispatch(
      IgnoreAdvertiser({
        data: {
          ids,
          action: "listing",
        },
      })
    )
      .then((res) => {
        setSelectedItems([]);

        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Advertiser(s) ignored");
        }
      })
      .catch((err) => {
        //
      });
  };

  return (
    <BMAHomeComponent
      has_open_ppc_policy={has_open_ppc_policy}
      has_no_faqs={has_no_faqs}
      is_free={is_free}
      is_ad_center={is_ad_center}
      allSelected={allSelected}
      setAllSelected={setAllSelected}
      goToAdd={goToAdd}
      onActivatePressed={onActivatePressed}
      onSearchChange={onSearchChange}
      onSearchSubmit={onSearchSubmit}
      loading={loading}
      data={data}
      onPageChange={getNewPage}
      networkOptions={networkOptions}
      onSelectedNetworkChange={onSelectedNetworkChange}
      onStatusChange={onStatusChange}
      network_status={network_status}
      currentPage={Number(page) || 1}
      searchTxtValue={searchTxtValue}
      onItemSelectChange={onItemSelectChange}
      selectedItems={selectedItems}
      handleIgnoreAdvertisers={handleIgnoreAdvertisers}
      ignoreLoading={ignoreLoading}
      error={error}
      queryParamValues={queryParamValues}
    />
  );
};

export default BMAHome;
