import { CSVDownload } from "react-csv";
import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
} from "react";
import { ExportResourceAction } from "redux/slices/clients/export-resource";
import DateRangeSelector from "components/common/DateRange";
import Spinner from "components/common/layout/Spinner";
import dateFormat from "dateformat";
import { User } from "types/model/User";
import Dropdown from "components/common/Dropdown";
import { IoIosStats } from "react-icons/io";
import { Link } from "react-router-dom";
import storeRanks from "constants/storeRanks";
import countries from "constants/operationCountries";
import { Option } from "types/option";
import DeleteButton from "components/common/DeleteButton";
import { ActionState } from "types/data";

type Props = {
  onFilterChange: ChangeEventHandler<HTMLInputElement>;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  total_items: number;
  selectedItems: Array<Record<string, any>>;
  setAllSelected?: Dispatch<SetStateAction<boolean>>;
  allSelected?: boolean;
  exportResourceData?: ExportResourceAction;
  onExportAll?: () => void;
  userOptions: User[];
  onChange: ChangeEventHandler<HTMLInputElement>;
  form?: Record<string, any>;
  networkOptions: Option[];
  couponFilters: Record<string, any>;
  title?: string;
  userAdded?: boolean;
  deleteAll?: () => void;
  deleteLoading?: boolean;
  isAutoCoupons?: Boolean;
  getCouponOfferTypesData?: ActionState;
};

const ActiveCouponsHeader = ({
  onFilterChange,
  onSearchSubmit,
  total_items,
  setAllSelected,
  selectedItems,
  allSelected,
  exportResourceData: { loading, data } = {
    loading: false,
    data: "",
    error: null,
  },
  onExportAll,
  onChange,
  form,
  deleteAll,
  userOptions,
  networkOptions,
  couponFilters,
  title,
  userAdded,
  deleteLoading,
  isAutoCoupons,
  getCouponOfferTypesData,
}: Props) => {
  console.log("getCouponOfferTypesData", getCouponOfferTypesData);
  return (
    <>
      <div className="border-b dark:border-gray-700 mx-4">
        <div className="flex items-center justify-between space-x-4 pt-3">
          <div className="flex-1 flex items-center space-x-3">
            <h5 className="dark:text-white font-semibold">
              {title || "Active Coupon Offers"}
            </h5>
          </div>
        </div>

        <div className="flex items-center justify-between my-1">
          <div className="flex items-center">
            <div className="w-full gap-4 items-center py-4 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
              <form className="w-72 md:mr-4" onSubmit={onSearchSubmit}>
                <label
                  htmlFor="default-search"
                  className="text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="search_text"
                    name="search_text"
                    onChange={onFilterChange}
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Program..."
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 top-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-r-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Search
                  </button>
                </div>
              </form>
              <div>
                <Dropdown
                  options={networkOptions}
                  name="network"
                  value={couponFilters.network}
                  placeholder="Networks"
                  onChange={onChange}
                />
              </div>

              {isAutoCoupons && (
                <div>
                  <Dropdown
                    options={getCouponOfferTypesData?.data.map(
                      (el: Record<string, any>) => ({
                        label: el.name,
                        value: el.id,
                      })
                    )}
                    name="offer_type"
                    value={couponFilters.offer_type}
                    placeholder="Offer Type"
                    onChange={onChange}
                  />
                </div>
              )}

              {isAutoCoupons && (
                <div>
                  <Dropdown
                    options={[
                      { name: "Yes", id: "1" },
                      { name: "No", id: "0" },
                    ].map((el: Record<string, any>) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                    name="has_code"
                    value={couponFilters.has_code}
                    placeholder="Has Coupon Code"
                    onChange={onChange}
                  />
                </div>
              )}

              <div>
                <Dropdown
                  options={storeRanks.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                  value={couponFilters.rank}
                  name="rank"
                  placeholder="Rank"
                  onChange={onChange}
                />
              </div>
              <div>
                <Dropdown
                  options={countries.map((el) => ({
                    label: el,
                    value: el.toLowerCase(),
                  }))}
                  name="location"
                  placeholder="Location"
                  value={couponFilters.location}
                  onChange={onChange}
                />
              </div>

              {!userAdded && !isAutoCoupons && (
                <Dropdown
                  wrapperClassName="w-48"
                  className="py-3"
                  options={userOptions.map((el) => ({
                    label: el.first_name + " " + el.last_name,
                    value: el.email,
                  }))}
                  name="uploader"
                  placeholder="Uploader"
                  onChange={onChange}
                />
              )}
              {!userAdded && form && (
                <DateRangeSelector
                  disableConfirm
                  form={form}
                  inputWrapperClassName="w-48"
                  onChange={onChange}
                />
              )}
            </div>
          </div>

          {selectedItems.length === 0 && (
            <div className="flex justify-end flex-1  ">
              <div className="flex items-center gap-4 mx-4">
                <p className="font-medium text-gray-900 text-xs">
                  {total_items} Items
                </p>
              </div>
            </div>
          )}

          {
            <div className="flex items-center">
              {selectedItems.length > 0 && (
                <div className="flex items-center gap-4 mx-4">
                  <p className="font-medium text-gray-900 text-xs">
                    {" "}
                    {allSelected ? total_items : selectedItems.length} Selected
                    out of {total_items}
                  </p>
                  {!userAdded && (
                    <button
                      className="font-medium text-primary-700 text-xs underline"
                      onClick={() => setAllSelected?.((value) => !value)}
                    >
                      {allSelected ? (
                        <span>Cancel</span>
                      ) : (
                        <span>Select all {total_items}</span>
                      )}
                    </button>
                  )}
                </div>
              )}

              {!!data && form ? (
                <>
                  <CSVDownload
                    filename={`active_coupons_${dateFormat(
                      new Date(form.startDate),
                      "dd_mm_yyyy"
                    )}_${dateFormat(new Date(form.endDate), "dd_mm_yyyy")}`}
                    data={data}
                    target="_blank"
                  />
                </>
              ) : (
                <>
                  {userAdded &&
                  typeof deleteAll === "function" &&
                  selectedItems.length > 0 ? (
                    <DeleteButton
                      onClick={deleteAll}
                      deleteLoading={!!deleteLoading}
                    />
                  ) : (
                    <>
                      {!userAdded && (
                        <div>
                          {loading ? (
                            <Spinner className="h-6 w-6" />
                          ) : (
                            <button onClick={onExportAll}>
                              <svg
                                className="w-4 h-4 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              {!userAdded && !isAutoCoupons && (
                <div className="py-1">
                  <Link
                    to={"/contributions/coupons"}
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    <div className="flex items-center gap-2">
                      <IoIosStats /> <p>Performance</p>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ActiveCouponsHeader;
