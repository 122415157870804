import DataTable from "components/common/layout/DataTable";
import CouponRow from "components/coupons/StoreCouponRow";
import COUPON_COLUMNS from "constants/tables/headers/oma/coupons";
import sortCoupons from "redux/slices/clients/coupons/sortCoupons";
import { PageNumPaginatedData } from "types/data";

type Props = {
  loading: boolean;
  data: PageNumPaginatedData<Record<string, any>>;
  onPageChange: (p: number) => void;
  page: number;
};

const StoreCoupons = ({ data, loading, page, onPageChange }: Props) => {
  const sorted = sortCoupons(data.results);

  sorted.forEach((el) => {
    console.log("el.sorting_for_store", el.sorting_for_store);
  });

  return (
    <div className="w-full mt-4">
      <DataTable
        loading={loading}
        columns={COUPON_COLUMNS.filter(
          (el) =>
            !el.component &&
            el.label !== "Program Name" &&
            el.label !== "Created"
        )}
        rows={sorted}
        rowRenderer={(row) => (
          <CouponRow key={row.id} row={row} skipFields={["Program Name"]} />
        )}
        currentPage={page}
        onPageChange={onPageChange}
        totalCount={data.count}
      />
    </div>
  );
};

export default StoreCoupons;
