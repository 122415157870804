import DataTable from "components/common/layout/DataTable";
import TableHeader from "components/links/TableHeader";
import { Option } from "types/option";
import { ChangeEventHandler, FormEventHandler } from "react";
import { ActionState, PaginatedActionState } from "types/data";
import { Link } from "types/model/Link";
import Message from "components/common/Message";
import NEWSLETTER_COLUMNS from "constants/tables/headers/oma/storeNewsletterFeed";
import NewsLetterFeedRow from "components/links/NewsLetterFeedRow";

type Props = {
  getAllLinksState: PaginatedActionState<Link>;
  onActivatePressed: (arg: Record<string, any>) => void;
  completeNewsletter: (item: Record<string, any>) => void;
  completeNewsLetterData: ActionState;
  onPageChange: (page: number) => void;
  currentPage: number;
  onSearchSubmit: FormEventHandler<HTMLFormElement>;
  onFilterChange: ChangeEventHandler<HTMLInputElement>;
  networkOptions: Option[];
  onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement>;
  onSelectedRankChange: ChangeEventHandler<HTMLInputElement>;
  rank?: string;
  network: string;
  store_location?: string;
  createCouponData: ActionState;
  has_un_matched_emails: string;
};

const OMADirectStoreNewslettersComponent = ({
  onActivatePressed,
  getAllLinksState: { loading, data, error },
  onPageChange,
  currentPage,
  onSearchSubmit,
  onFilterChange,
  completeNewsLetterData,
  completeNewsletter,
  networkOptions,
  onSelectedNetworkChange,
  onSelectedRankChange,
  rank,
  network,
  createCouponData,
  has_un_matched_emails,
}: Props) => {
  if (error?.detail) {
    return <Message title="Error!" info description={error?.detail} />;
  }

  return (
    <div>
      <DataTable
        totalCount={data.meta.count}
        onPageChange={onPageChange}
        columns={NEWSLETTER_COLUMNS}
        rows={data.items}
        currentPage={currentPage}
        rowRenderer={(row: any) => (
          <NewsLetterFeedRow
            item={row}
            onReviewPressed={onActivatePressed}
            completeNewsletter={completeNewsletter}
            completeNewsLetterData={completeNewsLetterData}
            createCouponData={createCouponData}
          />
        )}
        header={
          <TableHeader
            updateDate={data.items?.[0]?.updated_at}
            total_items={data.meta.count}
            onSearchSubmit={onSearchSubmit}
            onFilterChange={onFilterChange}
            advertiser_count={data.meta.extra?.advertiser_count || 0}
            networkOptions={networkOptions}
            onSelectedNetworkChange={onSelectedNetworkChange}
            onSelectedRankChange={onSelectedRankChange}
            rank={rank}
            network={network}
            title="Direct Store Newsletters"
            isNewsletter
            has_un_matched_emails={has_un_matched_emails}
          />
        }
        loading={loading}
      />
    </div>
  );
};

export default OMADirectStoreNewslettersComponent;
