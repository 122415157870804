import dateFormat from "dateformat";
import useLoadAuth from "hooks/useLoadAuth";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import TabCreateCoupon from "layout/oma/TabCreateCoupon";
import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import createUpdateCoupon from "redux/thunks/app/api-clients/createUpdateCoupon";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";

type Props = {
  initialForm?: Record<string, any>;
  hiddenFormFields?: Array<string>;
  programMissing?: boolean;
  setMultiOfferMatchOpen?: Dispatch<SetStateAction<boolean>>;
  hasPendingOffers?: boolean;
};

const CreateUpdateCoupon = ({
  initialForm,
  hiddenFormFields,
  programMissing,
  setMultiOfferMatchOpen,
  hasPendingOffers,
}: Props) => {
  const { state } = useLocation();
  const { user } = useLoadAuth();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [localErrors, setLocalErrors] = useState({});

  const dispatch = useAppDispatch();

  const { getProgram: getProgramData } = useAppSelector((state) => state);

  const {
    createUpdateCoupon: createUpdateCouponData,
    getLink: { data: getLinkData },
  } = useAppSelector(({ getClients, createUpdateCoupon, getLink }) => ({
    getClients,
    createUpdateCoupon,
    getLink,
  }));

  const link = state || getLinkData.item;

  const initialFormState = {
    start_date:
      link?.start_date && dateFormat(new Date(link?.start_date), "yyyy-mm-dd"),
    expiration_date:
      link?.end_date && dateFormat(new Date(link?.end_date), "yyyy-mm-dd"),
    store: getProgramData.data.id,
    discount_code: link?.coupon_code,
    title: link?.network_advertiser ? link?.name : null,
    link_id: link?.link_id,
    description: link?.description,
    custom_url: link?.network_advertiser
      ? getProgramData.data.affiliate_website_url
      : link?.affiliate_website_url,
    apply_default_homepage_url: true,
    destination_coupon_url: link?.sharing?.destination_url,
    destination_affiliate_url: link?.sharing?.tracking_url,
    exclusions: false,
    is_exclusive: false,
    offer_type: "",
    savings_amount: 0,
    savings_percent: 0,
    savings_minimum_order: 0,
    up_to_savings_percent: 0,
    offer_starting_at: 0,
    ...initialForm,
    offer_features: initialForm?.offer_features || [],
  };

  const [form, setForm] = useState<Record<string, any>>(initialFormState);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.name === "apply_default_homepage_url") {
      setForm((f) => ({
        ...f,
        apply_default_homepage_url: !!e.target.value,
        custom_url: !!e.target.value
          ? link?.sharing.tracking_url
          : getProgramData.data.affiliate_website_url,
      }));
    }
    setForm((f) => ({
      ...f,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (getProgramData.data.id) {
      setForm((f) => ({
        ...f,
        store: getProgramData.data.id,
        custom_url: getProgramData.data.affiliate_website_url,
      }));
    }
  }, [getProgramData.data.id]);

  useEffect(() => {
    if (getLinkData.item.id) {
      setForm((form) => ({
        ...form,
        start_date:
          getLinkData.item?.start_date &&
          dateFormat(new Date(getLinkData.item?.start_date), "yyyy-mm-dd"),
        expiration_date:
          getLinkData.item?.end_date &&
          dateFormat(new Date(getLinkData.item?.end_date), "yyyy-mm-dd"),
        store: getProgramData.data.id,
        discount_code: getLinkData.item?.coupon_code,
        title: getLinkData.item?.network_advertiser
          ? getLinkData.item?.name
          : null,
        link_id: getLinkData.item?.link_id,
        description: getLinkData.item?.description,
        custom_url: getLinkData.item?.network_advertiser
          ? getProgramData.data.affiliate_website_url
          : getLinkData.item?.affiliate_website_url ||
            getProgramData.data.affiliate_website_url,
        destination_coupon_url: getLinkData.item?.sharing?.destination_url,
        destination_affiliate_url: getLinkData.item?.sharing?.tracking_url,
      }));
    }
  }, [getLinkData.item.id]);

  useEffect(() => {
    if (initialForm?.title) {
      setForm((form) => ({
        ...form,
        ...initialForm,
      }));
    }
  }, [initialForm?.title]);

  const createCoupon = () => {
    if (programMissing) {
      toast.error("Please choose a program");
      return;
    }
    dispatch(
      createUpdateCoupon({
        data: {
          ...form,
          approved: true,
          is_active: true,
          start_date: form.start_date || null,
          expiration_date: form.expiration_date || null,
          activateNew: form.id ? false : true,
          sorting_for_store:
            getProgramData.data.number_of_coupons === 0
              ? "1"
              : getProgramData.data.sorting_for_store,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const activatedLink = params.get("link");

        if (hasPendingOffers) {
          setMultiOfferMatchOpen?.(true);
        }

        if (activatedLink) {
          const url = new URL(window.location.href);
          url.searchParams.delete("link");
          navigate(url.search);
        }

        if (form.id) {
          toast.success("Changes saved");
          form.cancelFn?.();
          getActiveCoupons({
            page: 1,
            store_slug: getProgramData.data.slug,
          });
        } else {
          setForm({
            ...initialFormState,
            discount_code: "",
            title: "",
            link_id: "",
            description: "",
            start_date: "",
            end_date: "",
            custom_url: getProgramData.data.affiliate_website_url,
          });
          setUpdateSubmitted(false);
          toast.success("Activated successfully");
        }
      }
    });
  };

  const deactivateCoupon = () => {
    dispatch(
      createUpdateCoupon({
        data: {
          id: form.id,
          offer_type: form.offer_type || "sales",
          activateNew: false,
          deactivated: true,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (form.id) {
          toast.success("Changes saved");
          form.cancelFn?.();
        }
      }
    });
  };

  const handleUpdateClicked = () => {
    if (!form.offer_type) {
      setLocalErrors((errors) => ({
        ...errors,
        offer_type: "This field is required",
      }));
      return;
    } else {
      setLocalErrors((errors) => ({
        ...errors,
        offer_type: null,
      }));

      if (
        form.offer_type === "sitewide amount" &&
        Number(form.savings_amount) <= 0
      ) {
        setLocalErrors((e) => ({
          ...e,
          savings_amount: "Invalid value",
        }));
        setUpdateSubmitted(false);
        return;
      } else {
        setLocalErrors((e) => ({
          ...e,
          savings_amount: null,
        }));
      }

      if (
        form.offer_type === "sitewide percent" &&
        Number(form.savings_percent) <= 0
      ) {
        setLocalErrors((e) => ({
          ...e,
          savings_percent: "Invalid value",
        }));
        setUpdateSubmitted(false);
        return;
      } else {
        setLocalErrors((e) => ({
          ...e,
          savings_percent: null,
        }));
      }

      setUpdateSubmitted(true);
    }
  };

  return (
    <TabCreateCoupon
      form={form}
      error={{
        ...createUpdateCouponData.error?.error,
        ...createUpdateCouponData.error,
        ...localErrors,
      }}
      handleSubmit={createCoupon}
      handleUpdateClicked={handleUpdateClicked}
      onChange={onChange}
      state={link}
      updateSubmitted={updateSubmitted}
      submitting={createUpdateCouponData.loading}
      deactivateCoupon={deactivateCoupon}
      user={user}
      hiddenFormFields={hiddenFormFields}
    />
  );
};

export default CreateUpdateCoupon;
