import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "helpers/axios";

export default createAsyncThunk(
  "admin/advertisers/affiliate-newsletters/generateCouponInfo/",
  async (data: Record<string, any>, ThunkAPI) => {
    try {
      const response = await axios.get(
        `/admin/newsletter-feed/affiliate-newsletters/gen-coupon-info/?id=${data.id}`
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
