import axios from "axios";
import axiosRetry from "axios-retry";
import { IClient } from "client-apis";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import {
  LOCAL_CLIENT_ACCESS_EXTRA_KEYS,
  LOCAL_KEY_SELECTED_CLIENT_ID,
} from "constants/storageKeys";
import { ExportResourceParams } from "redux/thunks/app/api-clients/exportCSVResource";

export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const tokens = JSON.parse(
      localStorage.getItem(LOCAL_CLIENT_ACCESS_EXTRA_KEYS) || JSON.stringify({})
    );
    if (tokens) {
      config.headers.Authorization = `Bearer ${tokens["Discountcodes.com"]}`;
    }

    if (config.url) {
      const url = new URL(config.url);

      const client = url.searchParams.get("client");

      if (client) {
        url.searchParams.delete("client");
        config.url = url.href;
      }

      config.params = {
        ...config.params,
        client: client || localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRetry(axios, {
  retries: 4,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return !error.response || error.response?.status >= 500;
  },
});

instance.defaults.timeout = 180000;

export type GetStoreByNetworkPayload =
  | { network: string; mid: string; id?: string }
  | Record<string, any>;

const tokens = JSON.parse(
  localStorage.getItem(LOCAL_CLIENT_ACCESS_EXTRA_KEYS) || JSON.stringify({})
);

class DiscountCodes implements IClient {
  API_BASE = DISCOUNT_CODES_API;

  getActivePrograms(args: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/stores/`);

    Object.entries(args).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href, {
      headers: {
        "cache-control": "no-cache",
      },
    });
  }

  getAllCategories(args = {}) {
    const url = new URL(`${this.API_BASE}/categories/`);

    Object.entries(args).forEach(([k, v]) => {
      url.searchParams.set(k, String(v));
    });

    return instance.get(url.href, {
      headers: {
        "cache-control": "no-cache",
      },
    });
  }

  searchPrograms(
    q: string,
    client_site_id?: string,
    extraParams?: Record<string, any>
  ) {
    const url = new URL(
      `${this.API_BASE}/stores/search-stores/?search_text=${encodeURIComponent(
        q
      )}&client=${
        client_site_id || localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
      }`
    );

    if (extraParams) {
      Object.entries(extraParams).forEach(([k, v]) => {
        url.searchParams.set(k, v);
      });
    }

    return instance.get(url.href);
  }

  getProgramDetails(look_up_key: string, client_id?: string) {
    const url = `${this.API_BASE}/stores/${look_up_key}?client=${client_id}`;

    return instance.get(url);
  }

  createUpdateCoupon(payload: any) {
    if (payload.id) {
      return instance.put(`${this.API_BASE}/coupons/${payload.id}`, payload);
    } else {
      return instance.post(`${this.API_BASE}/coupons/`, payload);
    }
  }

  getProgramByNetworkId(payload: GetStoreByNetworkPayload) {
    const url = new URL(`${this.API_BASE}/stores/store-by-network/`);
    Object.entries(payload).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href);
  }

  getActionEndpoint(payload: Record<string, any>) {
    if (payload.setNetwork) {
      return `${this.API_BASE}/stores/primary-network/`;
    }
    if (payload.removePrimaryNetwork) {
      return `${this.API_BASE}/stores/primary-network/`;
    }

    if (payload.id) {
      return `${this.API_BASE}/stores/${payload.slug}?id=${payload.id}`;
    }

    return `${this.API_BASE}/stores/`;
  }

  getActionMethod(payload: Record<string, any>) {
    if (payload.setNetwork || payload.activateNew) {
      return `POST`;
    }

    return payload.slug ? "PATCH" : "POST";
  }

  createUpdateProgram(payload: Record<string, any>) {
    const config = {
      url: this.getActionEndpoint(payload),
      method: this.getActionMethod(payload),
      data: {
        ...payload,
        slug: payload.new_slug || payload.slug,
      },

      headers: {
        "cache-control": "no-cache",
      },
    };
    return instance(config);
  }

  getStoreCoupons(payload: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/coupons/`);

    Object.entries(payload).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href);
  }

  getCategoryCoupons(payload: Record<string, any>) {
    return instance.get(`${this.API_BASE}/coupons/?categories=${payload.slug}`);
  }

  saveSortOrder(payload: Record<string, any>) {
    return instance.post(`${this.API_BASE}/coupons/sort-coupons`, payload);
  }

  createFaq(payload: Record<string, any>) {
    return instance.post(`${this.API_BASE}/stores/faqs/`, payload);
  }

  createUpdateCategory(payload: Record<string, any>) {
    if (payload.id) {
      return instance.put(
        `${this.API_BASE}/categories/${payload.id}/`,
        payload
      );
    }
    return instance.post(`${this.API_BASE}/categories/`, payload, {
      headers: {},
    });
  }

  getCategory(payload: Record<string, any>) {
    return instance.get(`${this.API_BASE}/categories/${payload.id}`);
  }

  getTrendingStores(args: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/stores/trending/`);

    Object.entries(args).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href);
  }
  saveTrendingStores(payload: Record<string, any>) {
    return instance.post(`${this.API_BASE}/stores/trending/`, payload);
  }

  getTopCategories() {
    return instance.get(`${this.API_BASE}/campaigns/top-categories/`);
  }
  saveTopCategories(payload: Record<string, any>) {
    return instance.post(`${this.API_BASE}/campaigns/top-categories/`, payload);
  }

  getCategoryStoreSpotlights(location: string) {
    return instance.get(
      `${this.API_BASE}/campaigns/category-store-spotlight/?location=${location}`
    );
  }

  getCouponSpotlights() {
    return instance.get(`${this.API_BASE}/campaigns/coupon-spotlight/`);
  }

  saveCategoryStoreSpotlights(payload: Record<string, any>) {
    return instance.post(
      `${this.API_BASE}/campaigns/category-store-spotlight/`,
      payload
    );
  }

  searchActiveCoupons(args: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/coupons/`);

    Object.entries(args).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href);
  }

  getEmailSubscribers(args: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/emails/news-letter/`);

    Object.entries(args).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href);
  }

  saveCouponSpotlights(payload: Record<string, any>) {
    return instance.post(
      `${this.API_BASE}/campaigns/coupon-spotlight/`,
      payload
    );
  }

  deletePaidPlacement(id: string) {
    return instance.delete(
      `${this.API_BASE}/campaigns/coupon-placements/${id}`
    );
  }
  getPaidPlacements(payload: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/campaigns/coupon-placements/`);

    Object.entries(payload).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href, {
      headers: {
        "cache-control": "no-cache",
      },
    });
  }

  addToPaidPlacements(payload: Record<string, any>) {
    return instance.post(
      `${this.API_BASE}/campaigns/coupon-placements/`,
      payload
    );
  }

  getHomeCoupons() {
    return instance.get(`${this.API_BASE}/campaigns/home-coupons/`);
  }

  saveHomeCoupons(payload: Record<string, any>) {
    return instance.post(`${this.API_BASE}/campaigns/home-coupons/`, payload);
  }

  updatePaidPlacement(payload: Record<string, any>) {
    return instance.patch(
      `${this.API_BASE}/campaigns/coupon-placements/${payload.id}`,
      payload
    );
  }

  checkPlacementPosAvailability(payload: Record<string, any>) {
    return instance.post(
      `${this.API_BASE}/campaigns/check-pos-availability/`,
      payload
    );
  }

  exportResource(params: ExportResourceParams) {
    if (params.resource === "stores") {
      const url = new URL(`${this.API_BASE}/stores/export-active-stores/`);

      Object.entries(params).forEach(([k, v]) => {
        url.searchParams.set(k, String(v));
      });

      return instance.get(url.href);
    }

    if (params.resource === "offers") {
      const url = new URL(`${this.API_BASE}/coupons/export-active-coupons/`);

      Object.entries(params).forEach(([k, v]) => {
        url.searchParams.set(k, String(v));
      });

      return instance.get(url.href);
    }
  }

  getCouponContributors(params: Record<string, any>) {
    const url = new URL(`${this.API_BASE}/coupons/contributions/`);

    Object.entries(params).forEach(([k, v]) => {
      url.searchParams.set(k, v);
    });

    return instance.get(url.href, {
      timeout: 180000,
    });
  }

  updateTopCategory(payload: Record<string, any>) {
    return instance.patch(
      `${this.API_BASE}/campaigns/top-categories/${payload.id}`,
      payload
    );
  }
}

export default DiscountCodes;
