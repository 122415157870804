import Dropdown from "components/common/Dropdown";
import Input from "components/common/Input";
import { offerTypes } from "constants/offer";
import React, { ChangeEventHandler } from "react";

type Props = {
  form: Record<string, any>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: Record<string, any> | null;
};

const OfferTypeSelector = ({ form, onChange, error }: Props) => {
  return (
    <React.Fragment>
      <Dropdown
        className="bg-white border-gray-200"
        name="offer_type"
        label="Offer Type"
        error={error?.offer_type}
        value={form.offer_type}
        onChange={(e) => {
          onChange({
            ...e,
            target: {
              ...e.target,
              name: "offer_type",
              value: e.target.value,
            },
          } as any);
        }}
        options={offerTypes.map((el) => ({
          label: el,
          value: el,
        }))}
      />

      {form.offer_type === "product deal" && (
        <div className="flex items-center gap-2">
          <Input
            label={"Savings amount"}
            name={"savings_amount"}
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            type="number"
            value={form.savings_amount}
          />

          <Input
            label="Savings %"
            name="savings_percent"
            type="number"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            value={form.savings_percent}
            error={error?.savings_percent}
          />
        </div>
      )}

      {form.offer_type === "sales" && (
        <div className="flex items-center gap-2 flex-wrap">
          <Input
            label="Savings %"
            name="savings_percent"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            onChange={onChange}
            value={form.savings_percent}
          />

          <Input
            label="Starting at"
            name="offer_starting_at"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            onChange={onChange}
            value={form.offer_starting_at}
          />

          <Input
            label="Up to Savings %"
            name="up_to_savings_percent"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            onChange={onChange}
            value={form.up_to_savings_percent}
          />
        </div>
      )}

      {(form.offer_type === "clearance" ||
        form.offer_type === "Newsletter Signup") && (
        <div className="flex items-center gap-2 flex-wrap">
          <Input
            label="Savings %"
            type="number"
            name="savings_percent"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            value={form.savings_percent}
          />

          <Input
            label="Savings amount"
            type="number"
            name="savings_amount"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            value={form.savings_amount}
          />

          <Input
            label="Up to Savings %"
            name="up_to_savings_percent"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            onChange={onChange}
            value={form.up_to_savings_percent}
          />
        </div>
      )}

      {form.offer_type.includes("sitewide") && (
        <div className="flex items-center gap-2">
          <Input
            label={
              form.offer_type === "sitewide percent"
                ? "Savings %"
                : "Savings amount"
            }
            name={
              form.offer_type === "sitewide percent"
                ? "savings_percent"
                : "savings_amount"
            }
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            onChange={onChange}
            value={
              form.offer_type === "sitewide percent"
                ? form.savings_percent
                : form.savings_amount
            }
            error={error?.savings_amount || error?.savings_percent}
          />

          <Input
            label="Minimum Order"
            name={"savings_minimum_order"}
            className="border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            onChange={onChange}
            value={form.savings_minimum_order}
          />
        </div>
      )}

      {form.offer_type.includes("free shipping") && (
        <div className="flex items-center gap-2">
          <Input
            label="Minimum Order"
            name={"savings_minimum_order"}
            type="number"
            className="border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            value={form.savings_minimum_order}
          />
        </div>
      )}

      {form.offer_type === "Refer a Friend" && (
        <div className="flex items-center gap-2 flex-wrap">
          <Input
            label="Savings %"
            type="number"
            name="savings_percent"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            value={form.savings_percent}
          />

          <Input
            label="Savings amount"
            type="number"
            name="savings_amount"
            className=" border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={onChange}
            value={form.savings_amount}
            error={error?.savings_amount}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default OfferTypeSelector;
