import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import getActiveCoupons from "redux/thunks/app/api-clients/getActiveCoupons";
import getShortCouponInfo from "./getShortCouponInfo";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import getClients from "redux/thunks/app/getClients";
import sortCoupons from "redux/slices/clients/coupons/sortCoupons";

type Props = {
  slug: string;
};

const useSiteLinks = ({ slug }: Props) => {
  const dispatch = useAppDispatch();

  const [topCoupon, setTopCoupon] = useState<Record<string, any> | null>(null);

  const [secondCoupon, setSecondCoupon] = useState<Record<string, any> | null>(
    null
  );

  const [siteLinks, setSiteLinks] = useState<Record<string, any>>({
    siteLink1: {
      text: "",
      description1: "",
      description2: "",
      finalURL: "",
    },

    siteLink2: {
      text: "",
      description1: "",
      description2: "",
      finalURL: "",
    },
    siteLink3: {
      text: "",
      description1: "",
      description2: "",
      finalURL: "",
    },
    siteLink4: {
      text: "",
      description1: "",
      description2: "",
      finalURL: "",
    },
  });
  const {
    getActiveCoupons: { data },
    getClients: { data: clients },
    getProgram: { data: getProgramData },
  } = useAppSelector(({ getActiveCoupons, getClients, getProgram }) => ({
    getActiveCoupons,
    getProgram,
    getClients,
  }));

  useEffect(() => {
    dispatch(getActiveCoupons({ store_slug: slug, page: 1 }));
  }, [slug]);

  useEffect(() => {
    if (data.results) {
      // const firstCoupon = data.results.find(
      //   (it: Record<string, any>) => it.sorting_for_store === "0"
      // );

      const firstCoupon = sortCoupons(data.results)[0];

      if (firstCoupon) {
        setTopCoupon(firstCoupon);
      } else {
        setTopCoupon(data.results[0]);
      }

      const secondSorted = sortCoupons(data.results)[1];

      // const secondSorted = data.results.find(
      //   (it: Record<string, any>) => it.sorting_for_store === "1"
      // );

      if (secondSorted) {
        setSecondCoupon(secondSorted);
      } else {
        setSecondCoupon(data.results[1]);
      }
    }
  }, [data.results]);

  useEffect(() => {
    if (!clients.items) {
      dispatch(getClients({ page: 1 }));
    }
  }, [clients.items]);

  useEffect(() => {
    const store = clients.items.find(
      (it) =>
        String(it.id) === localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
    );

    if (topCoupon) {
      const shortCouponStr = getShortCouponInfo(topCoupon);
      if (!shortCouponStr) {
        setSiteLinks((links) => ({
          ...links,
          siteLink1: {
            ...links.siteLink1,
            text: "Exclusive Coupons",
            description1: "Get Exclusive Discounts & Offers",
            description2: "Promo Codes & Deals",
            finalURL: `${store?.website_url}/${topCoupon.store.slug}?c=${topCoupon.id}`,
          },
        }));
      }

      if (topCoupon.store.location !== "uk" && shortCouponStr) {
        setSiteLinks((links) => ({
          ...links,
          siteLink1: {
            ...links.siteLink1,
            text: `${shortCouponStr} Coupon`,
            description1: `${topCoupon.store.name} Promo Codes & Deals`,
            description2: "Promo Codes & Deals",
            finalURL: `${store?.website_url}/${topCoupon.store.slug}?c=${topCoupon.id}`,
          },
        }));
      }

      if (topCoupon.store.location === "uk" && shortCouponStr) {
        setSiteLinks((links) => ({
          ...links,
          siteLink1: {
            ...links.siteLink1,
            text: `${shortCouponStr} Discount Voucher`,
            description1: "Get Exclusive Discounts & Offers",
            description2: "Promo Codes & Deals",
            finalURL: `${store?.website_url}/${topCoupon.store.slug}?c=${topCoupon.id}`,
          },
        }));
      }
    }

    if (secondCoupon) {
      const shortCouponStr = getShortCouponInfo(secondCoupon);

      if (!shortCouponStr) {
        setSiteLinks((links) => ({
          ...links,
          siteLink2: {
            ...links.siteLink2,
            text: "Exclusive Coupons",
            description1: "Get Exclusive Discounts & Offers",
            description2: "Offers & Deals",
            finalURL: store?.website_url
              ? `${store?.website_url}/${secondCoupon.store.slug}?c=${secondCoupon.id}`
              : "",
          },
        }));
      }

      if (secondCoupon.store.location !== "uk" && shortCouponStr) {
        setSiteLinks((links) => ({
          ...links,
          siteLink2: {
            ...links.siteLink2,
            text: `Deal: ${shortCouponStr} Coupon`,
            description1: `${secondCoupon.store.name} Promo Codes & Deals`,
            description2: "Offers & Deals",
            finalURL: store?.website_url
              ? `${store?.website_url}/${secondCoupon.store.slug}?c=${secondCoupon.id}`
              : "",
          },
        }));
      }

      if (secondCoupon.store.location === "uk" && shortCouponStr) {
        setSiteLinks((links) => ({
          ...links,
          siteLink2: {
            ...links.siteLink2,
            text: `Deal: ${shortCouponStr} Voucher`,
            description1: "Get Exclusive Discounts & Offers",
            description2: "Offers & Deals",
            finalURL: store?.website_url
              ? `${store?.website_url}/${secondCoupon.store.slug}?c=${secondCoupon.id}`
              : "",
          },
        }));
      }
    }

    if (store) {
      setSiteLinks((links) => ({
        ...links,
        siteLink3: {
          ...links.siteLink3,
          text: `${getProgramData.primary_category?.name || ""} Deals${
            getProgramData.primary_category?.name.length > 7
              ? ""
              : " and Discounts"
          }`,
          description1:
            getProgramData.location !== "uk"
              ? `Find Valid ${
                  getProgramData.primary_category?.name || ""
                } Coupons`
              : `Top Stores of ${
                  getProgramData.primary_category?.name || ""
                } Discount${
                  getProgramData.primary_category?.name?.length < 4
                    ? " Vouchers"
                    : "s"
                } `,
          description2: "Find Verified Coupons",
          finalURL: `${store?.website_url}/explore/${getProgramData.primary_category?.slug}`,
        },

        siteLink4: {
          ...links.siteLink4,
          text: `Top Categories To Save`,
          description1: `Browse All Categories for Discounts`,
          description2: "Exclusive Promo Codes & Sales",
          finalURL: `${store?.website_url}/categories`,
        },
      }));
    }
  }, [getProgramData, clients.items, topCoupon?.store?.name]);

  return siteLinks;
};

export default useSiteLinks;
