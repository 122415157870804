import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import Input from "./Input";
import TextArea from "./TextArea";

type Props = {
  faqs?: Record<string, any>[];
  onFormChange: ChangeEventHandler<HTMLInputElement>;
};
function DynamicFAQInput({ onFormChange, faqs }: Props) {
  const initialState: Record<string, any> = {};

  if (faqs?.length) {
    faqs.forEach((it, idx) => {
      initialState[`Question ${idx + 1}`] = it.question;
      initialState[`Answer ${idx + 1}`] = it.answer;
    });
  }

  const inputInitialState = {
    name: "0",
    qnValue: "",
    ansValue: "",
    id: new Date().getTime().toString(),
  };

  const [fieldsForm, setFieldsForm] = useState({
    inputs: [inputInitialState],
    form: initialState,
  });

  useEffect(() => {
    onFormChange({
      target: {
        name: "faqsForm",
        value: fieldsForm.form,
      },
    } as ChangeEvent<any>);
  }, [fieldsForm.form]);

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setFieldsForm((prev) => ({
      ...prev,
      form: { ...prev.form, [name]: value },
    }));
  };

  const handleAddInput = () => {
    setFieldsForm((prev) => ({
      ...prev,
      inputs: [
        ...prev.inputs,
        {
          name: String(fieldsForm.inputs.length),
          qnValue: "",
          ansValue: "",
          id: new Date().getTime().toString(),
        },
      ],
    }));
  };

  const handleRemoveInput = (idx: number) => {
    const {
      [`Question ${idx}`]: matched,
      [`Answer ${idx}`]: matchedAns,
      ...rest
    } = fieldsForm.form;

    setFieldsForm((prev) => ({
      ...prev,
      form: rest,
      inputs: prev.inputs.filter((input, index) => index + 1 !== idx),
    }));
  };

  useEffect(() => {
    if (faqs?.length) {
      setFieldsForm((prev) => ({
        ...prev,
        inputs: faqs.map((faq, index) => ({
          name: String(index),
          qnValue: faq.question,
          ansValue: faq.answer,
          id: faq.question + new Date().getTime().toString(),
        })),
      }));
    }
  }, []);

  return (
    <div>
      {fieldsForm.inputs.map((input, index) => (
        <div className="flex items-center gap-4 mb-8" key={input.id}>
          <div className="flex-1">
            <Input
              label={`Question ${index + 1}`}
              onChange={onChange}
              name={`Question ${index + 1}`}
              value={fieldsForm.form[`Question ${index + 1}`]}
            />
          </div>

          <div className="flex-1">
            <TextArea
              onChange={(e) => {
                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    name: `Answer ${index + 1}`,
                    value: e.target.value,
                  },
                } as unknown as ChangeEvent<HTMLInputElement>);
              }}
              // error={errors.description}
              label={`Answer ${index + 1}`}
              name={`Answer ${index + 1}`}
              value={fieldsForm.form[`Answer ${index + 1}`]}
            />
          </div>
          {fieldsForm.inputs.length > 0 && (
            <small
              className="cursor-pointer"
              onClick={() => handleRemoveInput(index + 1)}
            >
              Remove
            </small>
          )}
        </div>
      ))}

      {
        <small className="cursor-pointer block mb-4" onClick={handleAddInput}>
          Add {fieldsForm.inputs.length ? "another question" : "a question"}
        </small>
      }
    </div>
  );
}

export default DynamicFAQInput;
