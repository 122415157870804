import DataTable from "components/common/layout/DataTable";
import { ActionState, PaginatedActionState } from "types/data";
import MultipleSelectionInput from "components/common/MultipleSelectionInput";
import { ChangeEvent, ChangeEventHandler } from "react";
import DateRangeSelector from "components/common/DateRange";
import { PPC_REPORT_COLUMNS } from "constants/tables/headers/reporting/merchantReport";
import PPCReportRow from "layout/reporting/revenue/PPCReportRow";
import { AdDevice } from "types/model/sem";
import SearchForm from "components/common/SearchForm";
import { CampaignReportActionState } from "redux/slices/marketing/sem/getCampaignReports";
import formatNumber from "utils/strings/formatNumber";
import { Client } from "types/model/Client";
import Dropdown from "components/common/Dropdown";
import countries from "constants/operationCountries";
import { GoGraph } from "react-icons/go";
import PPCWeeklyTrend from "containers/marketing/campaign-reporting/PPCMarginTrend";
import { ArrowUpExportIcon } from "components/common/ExportButton";
import Button from "components/common/Button";

type Props = {
  getCampaignReportsState: CampaignReportActionState;
  onSubmit: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onCampaignClicked: (item: Record<string, any>) => void;
  resetFilters: (resetProgram?: boolean) => void;
  getDeviceTypesData: ActionState<AdDevice[]>;
  onPageChange: (page: number) => void;
  filterValues: Record<string, any>;
  getClientsData: PaginatedActionState<Client>;
  handleExport: () => void;
  downloadLoading: boolean;
};

const SEMHomeComponent = ({
  getCampaignReportsState: { loading, data },
  onChange,
  onSubmit,
  resetFilters,
  getDeviceTypesData,
  onPageChange,
  filterValues,
  getClientsData,
  handleExport,
  downloadLoading,
}: Props) => {
  return (
    <>
      <div className="mb-8">
        {filterValues.trend_view !== "1" ? (
          <div className="flex justify-end my-4  gap-4 py-2 flex-wrap">
            <div className="ml-16 ">
              <h5>
                <span className="text-gray-500 text-xs">Total Cost:</span>
                <span className="text-xs">
                  {" "}
                  {formatNumber(data.totals.total_cost / 1000000, {
                    prefix: "$",
                    decimals: 2,
                  })}
                </span>
              </h5>
            </div>

            <div className="mr-2">
              <h5>
                <span className="text-gray-500 text-xs">Total Clicks:</span>
                <span className="text-xs">
                  {" "}
                  {formatNumber(data.totals.total_clicks, {})}
                </span>
              </h5>
            </div>

            <div className="">
              <h5>
                <span className="text-gray-500 text-xs">Total Items:</span>
                <span className="text-xs">
                  {" "}
                  {formatNumber(data.data.meta.count, {})}
                </span>
              </h5>
            </div>
          </div>
        ) : (
          <div className="mt-4" />
        )}
        <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
          <div className="w-full lg:w-2/3 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center ">
            <div>
              <div className="mr-4">
                <SearchForm
                  wrapperClassName="w-44"
                  onChange={(e) => {
                    onChange({
                      target: {
                        name: "program_slug",
                        value: e.target.value,
                      },
                    } as ChangeEvent<HTMLInputElement>);
                  }}
                  placeholder="Search..."
                />
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <Dropdown
                placeholder="Client"
                options={getClientsData.data.items.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                name="client_id"
                onChange={onChange}
                loading={getClientsData.loading}
                value={filterValues.client_id}
                wrapperClassName="w-36"
              />
              <Dropdown
                options={countries.map((el) => ({
                  label: el,
                  value: el.toLowerCase(),
                }))}
                name="location"
                placeholder="Select Location"
                value={filterValues.location}
                onChange={onChange}
              />
              <div className="w-36">
                <MultipleSelectionInput
                  options={["google", "bing"].map((el) => ({
                    label: el,
                    value: el,
                    selected: filterValues.search_engines.includes(el),
                  }))}
                  name="search_engines"
                  renderAll
                  placeholder="Select sources"
                  onChange={onChange}
                />
              </div>
              <div className="w-36">
                <MultipleSelectionInput
                  options={getDeviceTypesData.data.map((el) => ({
                    label: el.name,
                    value: String(el.id),
                    selected: filterValues.devices.includes(String(el.id)),
                  }))}
                  name="devices"
                  renderAll
                  placeholder="Select devices"
                  onChange={onChange}
                />
              </div>
              <DateRangeSelector
                showClear
                inputWrapperClassName="w-48"
                form={{
                  startDate: filterValues.startDate,
                  endDate: filterValues.endDate,
                }}
                onChange={onChange}
              />
            </div>
          </div>

          {filterValues.trend_view !== "1" && (
            <div className="w-full mt-2 md:w-auto flex flex-col md:flex-row mb-3 md:mb-0 items-end md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button
                type="button"
                onClick={onSubmit}
                className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                <svg
                  className="h-3.5 w-3.5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Create Report
              </button>
            </div>
          )}
        </div>

        <div className="flex items-center justify-between  mt-3">
          <div className="flex items-center">
            <div className="flex items-center  mr-4">
              <input
                id="all-tasks"
                type="radio"
                value={filterValues.client_view}
                checked={filterValues.client_view === "1"}
                onChange={(e) => {
                  onChange({
                    target: {
                      ...e.target,
                      name: "ordering",
                      value: "total_cost",
                    },
                  } as ChangeEvent<any>);
                  onChange({
                    target: {
                      ...e.target,
                      name: "client_view",
                      value: "1",
                    },
                  } as ChangeEvent<any>);

                  onChange({
                    target: {
                      ...e.target,
                      name: "trend_view",
                      value: "0",
                    },
                  } as ChangeEvent<any>);
                }}
                name="client_view"
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="all-tasks"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Client View
              </label>
            </div>

            <div className="flex items-center  mr-4">
              <input
                id="all-tasks"
                type="radio"
                value={filterValues.client_view}
                checked={
                  filterValues.client_view === "0" &&
                  filterValues.trend_view !== "1"
                }
                onChange={(e) => {
                  onChange({
                    target: {
                      ...e.target,
                      name: "ordering",
                      value: "margin",
                    },
                  } as ChangeEvent<any>);
                  onChange({
                    target: {
                      ...e.target,
                      name: "client_view",
                      value: "0",
                    },
                  } as ChangeEvent<any>);
                  onChange({
                    target: {
                      ...e.target,
                      name: "trend_view",
                      value: "0",
                    },
                  } as ChangeEvent<any>);
                }}
                name="verified"
                className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="all-tasks"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Campaign view
              </label>
            </div>

            <button
              onClick={() => resetFilters()}
              className="block mx-2 py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              Reset
            </button>

            <button
              onClick={(e) => {
                onChange({
                  target: {
                    ...e.target,
                    name: "trend_view",
                    value: "1",
                  },
                } as ChangeEvent<any>);
              }}
            >
              <GoGraph size={16} />
            </button>
          </div>
          {filterValues.client_view !== "1" && (
            <>
              {downloadLoading ? (
                <Button className="my-0" loading />
              ) : (
                <ArrowUpExportIcon onClick={handleExport} />
              )}
            </>
          )}
        </div>
      </div>
      {filterValues.trend_view === "1" && <PPCWeeklyTrend />}

      {filterValues.trend_view !== "1" && (
        <DataTable
          currentPage={filterValues.page}
          rows={[{}, ...data.data.items]}
          onPageChange={onPageChange}
          loading={loading}
          renderHeader
          totalCount={data.data.meta.count}
          currentPageCount={data.data.items.length}
          columns={
            filterValues.client_view !== "1"
              ? PPC_REPORT_COLUMNS
              : PPC_REPORT_COLUMNS.filter(
                  (el) =>
                    ![
                      "location",
                      "campaign",
                      "search_engine",
                      "Network",
                    ].includes(el.key)
                ).map((el) => ({ ...el, isSortable: false }))
          }
          rowRenderer={(row: any, idx) => (
            <PPCReportRow item={row} totals={data.totals} index={idx || 0} />
          )}
        />
      )}
    </>
  );
};

export default SEMHomeComponent;
