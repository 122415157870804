import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PendingStoreNewslettersComponent from "layout/oma/PendingStoreNewslettersComponent";
import toast from "react-hot-toast";
import getNewLetterFeed from "redux/thunks/links/newsletters/getStoreNewLetterFeed";
import completeNewsletter from "redux/thunks/links/newsletters/completeStoreNewsletter";

const PendingStoreNewsletters = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { page, program_id, network, mid } = Object.fromEntries([
    ...searchParams,
  ]);

  const {
    getNewsletterFeed: getNewsletterFeedState,
    completeNewsLetter: completeNewsLetterData,
    createUpdateCoupon: createCouponData,
  } = useAppSelector(
    ({
      getNewsletterFeed,
      completeNewsLetter,
      affiliateNetworks,
      getAllLinksByProgram,
      createUpdateCoupon,
    }) => ({
      getNewsletterFeed,
      completeNewsLetter,
      affiliateNetworks,
      createUpdateCoupon,
      getAllLinksByProgram,
    })
  );

  const onActivatePressed = (link: Record<string, any>) => {
    navigate(
      `/oma/program?&mid=${link?.network_advertiser?.external_id}&program_id=${link?.network_advertiser?.id}&network=${link?.network_advertiser?.network}&link=${link.id}&tab=create-coupon`,

      {
        state: link,
      }
    );
  };

  useEffect(() => {
    dispatch(
      getNewLetterFeed({
        page: Number(page) || 1,
        external_id: mid,
        advertiser_id: program_id,
        network_id: network,
      })
    );
  }, [searchParams]);

  const handleCompleteNewsLetter = (link: Record<string, any>) => {
    dispatch(
      completeNewsletter({
        email_id: link.email_id,
        id: link.id,
      })
    )
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            getNewLetterFeed({
              page: Number(page) || 1,
              advertiser_id: program_id,
              external_id: mid,
              network_id: network,
            })
          );
          toast.success("Newsletter marked as complete");
        }
      })
      .catch((err) => {
        //
      });
  };

  return (
    <PendingStoreNewslettersComponent
      onActivatePressed={onActivatePressed}
      createCouponData={createCouponData}
      getAllLinksState={getNewsletterFeedState}
      completeNewsletter={handleCompleteNewsLetter}
      completeNewsLetterData={completeNewsLetterData}
    />
  );
};

export default PendingStoreNewsletters;
